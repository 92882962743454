var mena = window.mena || {};

(function ($) {
    'use strict';

    mena.main.init = function () {
        mena.main.stickyHeader();
        mena.main.mobileMenu();
    };

    mena.main.debounce = function(func, wait, immediate) {

        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };

    };

    mena.main.stickyHeader = function() {

        var stickyHeader = function() {
            var header = $( '#header' );
            // $( '#content' ).css( "margin-top", header.outerHeight() );

            if ( $( window ).scrollTop() > 1 ) {
                header.addClass( 'sticky' );
            } else {
                header.removeClass( 'sticky' );
            }
        }

        window.addEventListener('load', stickyHeader);
        window.addEventListener('scroll', mena.main.debounce( stickyHeader, 150 ));
        window.addEventListener('resize', mena.main.debounce( stickyHeader, 150 ));

    };

    mena.main.mobileMenu = function() {

        var respMenu = $( '.resp-menu' );
        var button   = $( '.js-menu-toggle' );
        var body     = $( 'body' );

        button.click(function(e) {
            body.toggleClass('menu-open');
            button.toggleClass('menu-toggle--active');
        });

        /* Sliding side menu, close on swipe */
        if ($().swipe) {
            respMenu.swipe({
                swipeRight: function(event, direction, distance, duration, fingerCount) {
                    body.removeClass('menu-open');
                },
                threshold: 20,
                excludedElements: ""
            });
            button.swipe({
                swipeLeft: function(event, direction, distance, duration, fingerCount) {
                    body.addClass('menu-open');
                },
                threshold: 20,
                excludedElements: ""
            });
        }

        /* Sliding side menu submenu */
        respMenu.find('.menu-item > a').click(function(e) {
            if ($(this).attr('href') == "#") {
                e.preventDefault();
                $('.sub-menu').not($(this).siblings()).slideUp(300);
                $(this).siblings('.sub-menu').slideToggle(300);
            }
        });

        /* Adding submenu arrows to the responsive navigation */
        respMenu.find('.sub-menu').each(function() {
            $(this).parent().append('<a class="submenu-button" href="javascript:void(0)"><svg class="arrow-down" width="15px" height="8px"><polygon fill="#fff" points="15.002,0 7.501,7.501 0,0 "></polygon></svg></a>');
        });

        $('.submenu-button').click(function() {
            $('.sub-menu').not($(this).siblings()).slideUp(300);
            $(this).prev('.sub-menu').slideToggle(100);
            $(this).toggleClass('rotate-arrow');
        });

    };

return mena.main.init();

}($));